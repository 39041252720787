import { Injectable } from '@angular/core';
import {
  Cloudinary,
  CloudinaryImage,
  CloudinaryVideo,
} from '@cloudinary/url-gen';
import { limitFit, pad, thumbnail } from '@cloudinary/url-gen/actions/resize';
import { ScriptUtils } from '../utils/script.utils';
import { MediaService } from 'src/app/generated-api-client';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';

declare var cloudinary: any;

@Injectable({
  providedIn: 'root',
})
export class CloudinaryService {
  cld: Cloudinary = new Cloudinary({
    cloud: {
      cloudName: 'dd3qzmlsr',
    },
  });

  constructor(private mediaApi: MediaService) {}

  metaImageUrl(publicId: string): string {
    return this.cld.image(publicId)
      .format('jpg')
      .quality('auto')
      .resize(limitFit().width(640).height(640))
      .toURL();
  }

  async openUploadWidget(format: string, folder: string): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      await ScriptUtils.loadScript(
        'cloudinary',
        'https://widget.cloudinary.com/v2.0/global/all.js'
      );

      cloudinary.openUploadWidget(
        {
          cloudName: 'dd3qzmlsr',
          uploadPreset: 'ml_default',
          api_key: '342654862727323',
          sources: ['local'], // restrict the upload sources to URL and local files
          multiple: false, //restrict upload to a single file
          folder: folder, //upload files to the specified folder
          clientAllowedFormats: [format], //restrict uploading to image files only,
          uploadSignature: (callback: (s: string) => void, params: any) => {
            this.mediaApi.apiAdminMediaSignParametersPost(params).subscribe({
              next: (signature: any) => {
                callback(signature);
              },
              error: (error: any) => {
                console.error(error);
              },
            });
          },
        },
        (error: any, result: any) => {
          if (!error && result && result.event === 'success') {
            resolve(result.info.public_id);
          }
        }
      );
    });
  }
}

