export class ScriptUtils {
  public static async loadScript(id: string, url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body = <HTMLDivElement>document.body;
      const existingScript = document.getElementById(id);
      if (!existingScript) {
        const script = document.createElement('script');
        script.id = id;
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        script.onload = () => resolve();
        body.appendChild(script);
      } else {
        resolve();
      }
    });
  }
}
